import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
export const pageTitle = "Armeria Newsletter vol. 4";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "armeria-newsletter-vol-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-4",
        "aria-label": "armeria newsletter vol 4 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 4`}</h1>
    <p {...{
      "className": "date"
    }}>{`14th July 2022`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-devs"
        }}>{`From the devs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-community"
        }}>{`From the community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#we-need-your-comments"
        }}>{`We need your comments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#good-first-issues"
        }}>{`Good first issues`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stay-tuned"
        }}>{`Stay tuned`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thank-you"
        }}>{`Thank you!`}</a></li>
    </ul>
    <video {...{
      "className": "hideOnReducedMotion",
      "src": "/bf04f0ae6df78db5375887fb6f8e2f78/armeria.m4v",
      "preload": "none",
      "autoPlay": true,
      "muted": true,
      "loop": true,
      "style": {
        "width": "282px",
        "height": "112px"
      }
    }}>{`
  `}<img parentName="video" {...{
        "src": "/2cbd433cd318026a1c3017f022dbc263/armeria.gif",
        "loading": "lazy",
        "width": 282,
        "height": 112
      }}></img>
    </video>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`✨ We've released several new versions since our last newsletter with a bunch of new exciting features!`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://armeria.dev/release-notes/1.17.0/"
              }}>{`1.17.0`}</a>
              <ul parentName="li">
                <li parentName="ul">{`Easier way to send and receive RESTful APIs`}</li>
                <li parentName="ul">{`Ability to limit the maximum length of Thrift messages`}</li>
                <li parentName="ul">{`Injecting dependencies in annotations`}</li>
                <li parentName="ul">{`Support for Kotlin nullable type (`}<inlineCode parentName="li">{`?`}</inlineCode>{`) in annotated services`}</li>
              </ul>
            </li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://armeria.dev/release-notes/1.16.0/"
              }}>{`1.16.0`}</a>
              <ul parentName="li">
                <li parentName="ul">{`New metrics for TLS handshake results`}</li>
                <li parentName="ul">{`Handling `}<a parentName="li" {...{
                    "href": "type://Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
                  }}>{`type://Decorator`}</a>{` annotation with `}<a parentName="li" {...{
                    "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
                  }}>{`type://GrpcService`}</a>{` for your gRPC stubs`}</li>
                <li parentName="ul">{`Programmatic `}<a parentName="li" {...{
                    "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
                  }}>{`type://Flags`}</a>{` configuration via Java SPI`}</li>
              </ul>
            </li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://armeria.dev/release-notes/1.15.0/"
              }}>{`1.15.0`}</a>
              <ul parentName="li">
                <li parentName="ul">{`Support for gRPC health check protocol`}</li>
                <li parentName="ul">{`Multipart file uploads in annotated services`}</li>
                <li parentName="ul">{`GraphQL support in `}<a parentName="li" {...{
                    "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
                  }}>{`type://DocService`}</a></li>
                <li parentName="ul">{`Refactored DNS resolver cache with higher hit ratio and reduced traffic`}</li>
                <li parentName="ul">{`New metrics that help you get alerts before your TLS certificates are expired`}</li>
              </ul>
            </li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://armeria.dev/release-notes/1.14.0/"
              }}>{`1.14.0`}</a>
              <ul parentName="li">
                <li parentName="ul">{`Experimental support for Scala 3`}</li>
                <li parentName="ul">{`Fluent conversion of `}<a parentName="li" {...{
                    "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
                  }}>{`type://HttpResponse`}</a>{` into a desired type using `}<a parentName="li" {...{
                    "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
                  }}>{`type://WebClient`}</a></li>
                <li parentName="ul">{`Various build options for gRPC using `}<a parentName="li" {...{
                    "href": "type://GrpcClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/grpc/GrpcClientBuilder.html"
                  }}>{`type://GrpcClientBuilder`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`👂 Armeria team is hosting `}<a parentName="li" {...{
            "href": "https://armeria.dev/community/office-hours/"
          }}>{`bi-weekly virtual office hours`}</a>{`.`}
          <ul parentName="li">
            <li parentName="ul">{`It was great talking with all of you through the video chat. We hope we can see you again in the near future. :)`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🎓 Try out this guided `}<a parentName="li" {...{
            "href": "https://armeria.dev/tutorials/rest/blog"
          }}>{`tutorial`}</a>{` for REST Service! This super friendly and simple tutorial will guide you to make a blog service, with which you can create, read, update and delete blog posts. In particular, you'll be using these Armeria features:`}
          <ul parentName="li">
            <li parentName="ul">{`Service annotations`}</li>
            <li parentName="ul">{`Request converter`}</li>
            <li parentName="ul">{`Parameter injection`}</li>
            <li parentName="ul">{`Exception handler`}</li>
            <li parentName="ul">{`Blocking task executor`}</li>
          </ul>
        </li>
        <li parentName="ul">{`🦾 MSA(Microservices Architecture): Harder, Better, Faster, Stronger`}
          <ul parentName="li">
            <li parentName="ul">{`In the session, `}<a parentName="li" {...{
                "href": "https://github.com/cnabro"
              }}><strong parentName="a">{`@cnabro`}</strong></a>{` introduces how `}<a parentName="li" {...{
                "href": "https://serieson.naver.com/"
              }}>{`Series On`}</a>{` migrated their monoliths into microservices using Armeria, gRPC, and GraphQL. He also mentions about how they monitored microservices using `}<a parentName="li" {...{
                "href": "https://zipkin.io/"
              }}>{`Zipkin`}</a>{` and `}<a parentName="li" {...{
                "href": "https://www.elastic.co/"
              }}>{`Elasticsearch`}</a>{`.`}</li>
            <li parentName="ul">{`Here are the `}<a parentName="li" {...{
                "href": "https://youtu.be/1TsGYR1dves"
              }}>{`Video`}</a>{` and `}<a parentName="li" {...{
                "href": "https://speakerdeck.com/line_devday2021/msa-microservices-architecture-harder-better-faster-stronger"
              }}>{`Slides`}</a>{` (also providing `}<a parentName="li" {...{
                "href": "https://youtu.be/95L-_82N1vg"
              }}>{`Korean`}</a>{` and `}<a parentName="li" {...{
                "href": "https://youtu.be/pXIdcgchvSU"
              }}>{`Japanese`}</a>{`)`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <div className="noBullet">
      <ul>
        <li parentName="ul">{`🤔 Your opinion means a lot to us. Please let us know what you think about these proposals:`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4289"
              }}>{`#4289`}</a>{` Supporting Spring Cloud Gateway integration(also considering `}<a parentName="li" {...{
                "href": "https://github.com/spring-cloud/spring-cloud-gateway/blob/main/spring-cloud-gateway-server/src/main/java/org/springframework/cloud/gateway/config/GatewayAutoConfiguration.java"
              }}>{`GatewayAutoConfiguration`}</a>{`, `}<a parentName="li" {...{
                "href": "https://github.com/spring-cloud/spring-cloud-gateway/blob/main/spring-cloud-gateway-server/src/main/java/org/springframework/cloud/gateway/config/HttpClientFactory.java"
              }}>{`HttpClientFactory`}</a>{`, and `}<a parentName="li" {...{
                "href": "https://github.com/reactor/reactor-netty/blob/main/reactor-netty-http/src/main/java/reactor/netty/http/client/HttpClient.java#L101"
              }}>{`HttpClient`}</a>{`)`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/4286"
              }}>{`#4286`}</a>{` Changing the insertion order of `}<a parentName="li" {...{
                "href": "type://ExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandlerFunction.html"
              }}>{`type://ExceptionHandlerFunction`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://github.com/line/armeria/issues/3820"
              }}>{`#3820`}</a>{` Providing a way to enable gRPC reflection service and making it enabled by default`}</li>
          </ul>
        </li>
      </ul>
    </div>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <div className="noBullet">
      <p>{`🔰 Want to contribute but not sure where to start from? Try one of these:`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/line/armeria/issues/4237"
          }}>{`#4237`}</a>{` Support for request scoped DataLoader for GraphQL-Java`}
          <pre parentName="li"><code parentName="pre" {...{
              "className": "language-java"
            }}>{`class GraphqlServiceBuilder {
    public GraphqlServiceBuilder dataLoaderRegistry(
      Function<ServiceRequestContext, DataLoaderRegistry>
      dataLoaderRegistrySupplier) {
         // This method will be mutually exclusive
         // with configureDataLoaderRegistry(...)
    }
}
`}</code></pre>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/line/armeria/issues/4347"
          }}>{`#4347`}</a>{` Show the full stack trace of `}<a parentName="li" {...{
            "href": "type://CompositeException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/CompositeException.html"
          }}>{`type://CompositeException`}</a>{` when the system property `}<inlineCode parentName="li">{`verboseExceptions`}</inlineCode>{` is `}<inlineCode parentName="li">{`true`}</inlineCode></li>
      </ul>
    </div>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['aboivin', 'ahnyujin', 'alexc-db', 'AmosDoan', 'AngerM', 'anuraaga', 'arhont375', 'be-hase', 'chris-ryan-square', 'clatisus', 'devdynam0507', 'di-seo', 'Dogacel', 'doppany', 'eisig', 'freevie', 'ghkim3221', 'gurudatta-carbon', 'heowc', 'hyangtack', 'icepeppermint', 'ikhoon', 'imasahiro', 'injae-kim', 'Jimexist', 'jrhee17', 'Jsing', 'jupiny', 'KarboniteKream', 'kezhenxu94', 'klurpicolo', 'kojilin', 'ks-yim', 'lan17', 'litols', 'LiYing2010', 'lucasamoroso', 'mauhiz', 'minwoox', 'natsumehu', 'ngyukman', 'nvidyala', 'pine', 'policeman-kh', 'r3mariano', 'sleeplesslord', 'syleeeee', 'ta7uw', 'techno', 'TheWeaVer', 'timothy-xflowpay', 'tobias-', 'tokuhirom', 'trustin', 'wasifaleem', 'woohhan', 'wooseongshin', 'wooyeong']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      